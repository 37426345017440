import React, { useState, useEffect, useMemo } from "react";
import { skip, takeUntil, switchMap, auditTime } from "rxjs/operators";

import { Nav } from "../components/Nav";
import { Meter } from "../components/Meter";
import { ScrollingContent } from "../components/ScrollingContent";

import {
  animateScroll,
  toggleScrollLock,
  resetScroll
} from "../services/scroll";
import { Subject } from "rxjs";

export function Kinesis({ user, notion }) {
  const [label] = useState("rightIndexFinger");
  const [probability, setProbability] = useState(0);
  const shouldStop = useMemo(() => new Subject(false), []);

  useEffect(() => {
    resetScroll();
    toggleScrollLock(false);
  }, []);

  useEffect(() => {
    if (!user || !notion) {
      return;
    }

    const predictionsSubscription = notion
      .predictions(label)
      .pipe(skip(1), takeUntil(shouldStop))
      .subscribe(prediction => {
        setProbability(prediction.probability);
      });

    return () => {
      predictionsSubscription.unsubscribe();
    };
  }, [label, shouldStop, notion, user]);

  useEffect(() => {
    if (!user || !notion) {
      return;
    }

    const kinesisSubscription = notion
      .kinesis(label)
      .pipe(
        skip(1),
        takeUntil(shouldStop),
        auditTime(1000),
        switchMap(async () => {
          toggleScrollLock(true);
          const atTheEnd = await animateScroll();
          toggleScrollLock(false);
          return atTheEnd;
        })
      )
      .subscribe(atTheEnd => {
        if (atTheEnd) {
          shouldStop.next(true);
          setProbability(0);
        }
      });

    return () => {
      kinesisSubscription.unsubscribe();
    };
  }, [label, shouldStop, notion, user]);

  return (
    <main>
      {user ? <Nav notion={notion} /> : null}
      <Meter value={probability} high={0.75} low={0.15} />
      <ScrollingContent />
    </main>
  );
}
