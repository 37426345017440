import React from "react";
import styled from "styled-components";

export const Section = styled.section`
  text-align: left;
  color: black;
  min-height: 100vh;
  color: white;
  padding: 30px 31vw 30px 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(80px + 2vw);
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  letter-spacing: -2px;

  & small {
    text-align: right;
    margin-top: 40px;
    font-weight: 400;
    font-size: 40px;
  }
`;

export const facts = [
  "With Notion, imagine using your index finger to scroll down.",
  "Did you know your brain is 73% water?",
  "It weighs 3 pounds.",
  "It contains 100 billion neurons.",
  "Each neuron can transmit 1,000 nerve impulses per second.",
  // "Neurons make tens of thousands of synaptic contacts with other neurons.",
  "Your brain generates enough electricity to power an LED light.",
  // "Your brain has a pattern of connectivity as unique as your fingerprints.",
  // "Your brain’s storage capacity is considered virtually unlimited.",
  "You just used your brain to learn about the brain."
  //"Thank you, NA GDE Summit!"
];

export function ScrollingContent() {
  return facts.map(fact => (
    <Section key={fact} className="section">
      {fact}
    </Section>
  ));
}
