import React from "react";

export function Footer() {
  return (
    <footer className="card-footer">
      <a
        className="card-link card-footer-cta"
        href="https://github.com/neurosity/thinktoscroll.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        by Neurosity
      </a>
    </footer>
  );
}
