import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;

  & meter::-webkit-meter-optimum-value,
  & meter::-webkit-meter-suboptimum-value,
  & meter::-webkit-meter-even-less-good-value {
    transition: 1s width;
  }

  & meter {
    width: 100%;
    height: 25px;
  }

  /* WebKit */
  & meter::-webkit-meter-bar {
    background: rgba(79, 90, 121, 0.85);
  }

  & meter::-webkit-meter-optimum-value {
    background: #86cc00;
  }

  & meter::-webkit-meter-suboptimum-value {
    background: #ffdb1a;
  }

  & meter::-webkit-meter-even-less-good-value {
    background: #cc4600;
  }

  & small {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 23px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: white;
  }
`;

export function Meter({
  value = 0,
  min = 0,
  max = 1,
  high = 0.5,
  low = 0.15,
  optimum = 1
}) {
  return (
    <Container>
      <meter
        value={value}
        min={min}
        max={max}
        high={high}
        low={low}
        optimum={optimum}
      />
      <small>{value.toFixed(3)}</small>
    </Container>
  );
}
