import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";
import $ from "jquery";

export const body = document.querySelector("body");
const page = $("html, body");

export function toggleScrollLock(value) {
  if (value) {
    enableBodyScroll(body);
  } else {
    disableBodyScroll(body);
  }
}

export function animateScroll(step = 400, reset = false) {
  const { scrollY, pageYOffset } = window;
  const scrollTop = reset ? 0 : Math.max(scrollY + step, pageYOffset);
  page.animate({
    scrollTop
  });

  return new Promise(resolve =>
    setTimeout(() => resolve(isAtTheEnd()), 1000)
  );
}

export function resetScroll() {
  animateScroll(0, true);
}

export function isAtTheEnd() {
  const d = document.documentElement;
  const offset = d.scrollTop + window.innerHeight;
  const height = d.offsetHeight;
  return offset >= height;
}
